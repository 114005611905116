export const treatmentEndReasons: { [key: string]: Array<string> } = {
  oxygenTherapy: [
    'co2Rise',
    'caiRise',
    'maskProblem',
    'wakingUp',
    'difficultyFallingAsleep',
    'fatigue',
    'socialReasons',
    'lackOfMotivation',
    'noMoreNeed',
    'switchedToAnotherDeviceTreatment',
    'death',
    'other',
  ],
  hfncTherapy: [
    'co2Rise',
    'caiRise',
    'maskProblem',
    'wakingUp',
    'difficultyFallingAsleep',
    'fatigue',
    'socialReasons',
    'lackOfMotivation',
    'noMoreNeed',
    'switchedToAnotherDeviceTreatment',
    'death',
    'other',
  ],
  papTherapy: [
    'co2Rise',
    'caiRise',
    'maskProblem',
    'wakingUp',
    'difficultyFallingAsleep',
    'fatigue',
    'socialReasons',
    'lackOfMotivation',
    'noMoreNeed',
    'switchedToAnotherDeviceTreatment',
    'death',
    'other',
  ],
  madTherapy: ['adverseEffect', 'notWorking', 'patientsWish', 'other'],
  other: ['other'],
};

export const defaultPapSettingsUnits: { [key: string]: string } = {
  cpap: 'cmH2O',
  constantPressure2pv: 'cmH2O',
  eep: 'cmH2O',
  ipap: 'cmH2O',
  ipapMax: 'cmH2O',
  targetVa: 'ml',
  targetFrequency: '/min',
  epap: 'cmH2O',
  epapMin: 'cmH2O',
  epapMax: 'cmH2O',
  respiratoryRate: '/s',
  mv: 'l/min',
  vt: 'ml',
  ibw: 'vt/kg',
  tiMin: 's',
  tiMax: 's',
  psMin: 'cmH2O',
  psMax: 'cmH2O',
  pressureSupport: 'cmH2O',
  settingPressure: 'cmH2O',
  riseTime: 'ms',
  inhalationTimeMin: 's',
  inhalationTimeMax: 's',
  backgroundFrequency: 'inhalationsPerMinute',
};

export const defaultPapTreatmentResponseUnits: { [key: string]: string } = {
  machineOperatingHours: 'h',
  patientHoursOfUsePerDay: 'h',
  daysWithLessThan4HoursOfUse: 'day',
  daysWithLessThan4HoursOfUsePercentage: '%',
  pressure95Percentile: 'cmH2O',
  pressureMax: 'cmH2O',
  pressureMedian: 'cmH2O',
  maskLeakage95PercentilePerMinute: 'l/min',
  maskLeakage95PercentilePerSecond: 'l/s',
  maskLeakageMedian: 'l/min',
  ahi: '/h',
  ai: '/h',
  aiTotal: '/h',
  aiCentral: '/h',
  hi: '/h',
  tidalVolume95Percentile: 'ml',
  tidalVolumeMedian: 'ml',
  respiratoryRate: 'inhalationsPerMinute',
  respiratoryRate95Percentile: 'inhalationsPerMinute',
  respiratoryRateMedian: 'inhalationsPerMinute',
  respiratoryRateMin: 'inhalationsPerMinute',
  respiratoryRateMax: 'inhalationsPerMinute',
  targetVentilation: 'l/min',
  minuteVentilation95Percentile: 'l/min',
  minuteVentilationMedian: 'l/min',
  spontT: '%',
  spontC: '%',
};
