import * as React from 'react';
import { IntlProvider } from 'react-intl';
import { useAppSelector as useSelector } from 'Store/index';

import fiMessages from '../../../packages/neuro-localizations/l18n/fi.json';
import enMessages from '../../../packages/neuro-localizations/l18n/en.json';

import browserLanguage from '../../utility/browserLanguage';
import flattenMessages from '../../utility/flattenMessages';
import { makeLog } from '../../utility/logger';
import { allDiagnoses } from '../../routes/Diagnosis/utils/definitions';
import { fetchWithOptions } from '../../utility/fetch';
import { parseJWTFromCookie } from '../../utility/jwtAuthTools';

export const isLocale = (locale: any): locale is Locale => locale === 'fi' || locale === 'en';

export type TMessages = { [key in Locale]: { [key in LocalizationKey]: string } };
const _messages = {
  fi: flattenMessages(fiMessages),
  en: flattenMessages(enMessages),
} as TMessages;

export const localeDefault = 'fi';

// Locales currently available for selection
const availableLocales: Array<Locale> = ['fi', 'en'];

export const LocalizerCtx = React.createContext<{
  messages: TMessages;
  setLocale?: (locale: Locale) => void;
  availableLocales: Array<Locale>;
  locale?: Locale;
}>({
  messages: _messages,
  availableLocales,
});

/**
 * Component in charge of maintaining the state of diagnosis localization strings in the UI.
 * Any localization missing from hard-coded values (diagnosis.opts.{code}) is fetched from
 * ICD-10 service. To override fetching, i.e. prevent it from happening, define a hard-coded
 * value in the corresponding package (neuro-localizations/l18n/{fi or en}.json).
 */
const Localizer = (props: { children: JSX.Element }): JSX.Element => {
  const [messages, setMessages] = React.useState<TMessages>(_messages);
  const [locale, setLocale] = React.useState<Locale>(browserLanguage);

  const uiLanguage = useSelector((state: IState) => state.settings.userSettings.uiLanguage);

  React.useEffect(() => {
    if (uiLanguage && locale !== uiLanguage) setLocale(uiLanguage);
  }, [uiLanguage]);

  /* On first mount find any missing diagnosis (dx) localizations, query them
  from ICD-10 service and then set the received results in state. */
  React.useEffect(() => {
    const missingDxLocalizations: TDiagnosis[] = [];
    allDiagnoses.forEach((dx) => {
      if (!messages[locale][`diagnosis.opts.${dx}`]) missingDxLocalizations.push(dx);
    });
    // none missing -> do nothing; return
    if (missingDxLocalizations.length < 1) return;
    // otherwise query each missing dx code from ICD-10 service
    const queryArray: Promise<ICD10Item[]>[] = [];
    missingDxLocalizations.forEach((dx) => {
      const queryString = dx.replace('#', '');
      queryArray.push(
        fetchWithOptions(
          `/api/icd-code-search/v1/search?search=${queryString}&locale=${locale}`,
          { neurojwt: parseJWTFromCookie() },
          { method: 'GET' },
        ).then((res: Response) => {
          if (res.status === 200) return res.json();
          else return [] as ICD10Item[];
        }),
      );
    });
    // once all are fetched, set the received results in state
    Promise.all(queryArray)
      .then((responses) => {
        const newMessages = { ...messages };
        responses.forEach((dxQueryResult, i) => {
          const dxCode = missingDxLocalizations[i];
          const fetchedDescription = dxQueryResult.find((r) => r.icd10code === dxCode)?.icd10description;
          newMessages[locale][`diagnosis.opts.${dxCode}`] = fetchedDescription
            ? `${dxCode} ${fetchedDescription}`
            : dxCode; // fallback to the ICD-10 code only
        });
        setMessages(newMessages);
      })
      .catch(() => {
        return;
      });
  }, [locale]);

  const setNewLocale = (newLocale: Locale) => {
    if (newLocale !== locale) {
      setLocale(newLocale);
    }
  };

  return (
    <LocalizerCtx.Provider value={{ messages: messages, availableLocales, setLocale: setNewLocale, locale }}>
      <LocalizerCtx.Consumer>
        {(value): JSX.Element => (
          <IntlProvider
            locale={locale}
            messages={value.messages[locale] || value.messages[localeDefault]}
            onError={(error: any): void => makeLog('Warning', { name: 'Localization error', message: error })}
          >
            {props.children}
          </IntlProvider>
        )}
      </LocalizerCtx.Consumer>
    </LocalizerCtx.Provider>
  );
};

export default Localizer;
