import { IAddon, IData, IDataPoint, IEvent, IItem } from 'Components/sq-graphics/interfaces';
import {
  exists,
  formatTime,
  isNumber,
  isPartialDate,
  isRecord,
  partialDateToValue,
  sortPartialDate,
} from 'neuro-utils';
import { defaultPapSettingsUnits, defaultPapTreatmentResponseUnits } from 'Routes/Treatment/Document/config';
import { papSettingsFields, papTreatmentResponseFields } from 'Routes/Treatment/Document/definitions';
import { patientHasRespiratoryFailureDiagnosis, patientHasSleepApneaDiagnosis } from 'Routes/Treatment/utils';
import createGraphDescriptions, { FDataFieldLocalizer } from './createGraphDescriptions';
import { IReadonlyDocument } from 'Store/readonly/readonlyGlobals';

type TTreatmentType =
  | IPatientDoesNotWantRespiratorySupportTherapy
  | IOxygenTherapy
  | IOxygenTherapySettings
  | IHFNCTherapy
  | IHFNCSetting
  | IPAPTherapy
  | IMADTherapy
  | IMADControl
  | ISurgicalTreatment
  | ISurgicalTreatmentControl
  | IOtherTreatment;

const getTreatmentUnit = (fieldName: string, fm: (id: string) => string): { [key: string]: string } | string => {
  return (
    {
      ...defaultPapSettingsUnits,
      ...defaultPapTreatmentResponseUnits,
      backgroundFrequency: fm('treatment.papTherapy.inhalationsPerMinuteShort'),
      daysWithLessThan4HoursOfUse: fm('treatment.papTherapy.day'),
      respiratoryRate95Percentile: fm('treatment.papTherapy.inhalationsPerMinuteShort'),
      respiratoryRateMedian: fm('treatment.papTherapy.inhalationsPerMinuteShort'),
      respiratoryRateMin: fm('treatment.papTherapy.inhalationsPerMinuteShort'),
      respiratoryRateMax: fm('treatment.papTherapy.inhalationsPerMinuteShort'),
      flow: fm('treatment.oxygenTherapy.settings.litersPerMinute'),
      flowRate: fm('treatment.hfncTherapy.settings.litersPerMin'),
      supplementalOxygen: '%',
      oxygenFlow: 'l/min',
      oxygenFlowPercent: '%',
      oxygenSaturation: '%',
      respiratoryRate: fm('treatment.papTherapy.inhalationsPerMinuteShort'),
      '': fm('treatment.madTherapy.controls.madUsageFrequencyPlaceholder'),
    }[fieldName] ?? ''
  );
};

const createTreatmentTooltipDescription = (
  d: TTreatmentType | IReadonlyDocument,
  fields: string[],
  locPath: string,
  optsLocPath: string,
  fm: (id: string) => string,
  freeTextFields?: string[],
): IItem['description'] | IEvent['description'] => {
  return fields.map((f) => {
    const value = d[f as keyof TTreatmentType] as unknown as number | string | boolean | Time;

    const isFreeTextField = (field: string) => (freeTextFields || []).includes(field);

    const getUnit = () => {
      switch (f) {
        case 'flow': {
          if ('implementationOfTreatment' in d && d.implementationOfTreatment === 'venturiMask') {
            return getTreatmentUnit('oxygenFlowPercent', fm);
          }
          return getTreatmentUnit(f, fm);
        }
        default: {
          return getTreatmentUnit(f, fm);
        }
      }
    };

    const formatValue = () => {
      switch (typeof value) {
        case 'number': {
          return `${value} ${getUnit()}`;
        }
        case 'string': {
          return isFreeTextField(f) ? value : fm(optsLocPath + value);
        }
        case 'boolean': {
          return value ? fm('general.yes') : fm('general.no');
        }
        default: {
          if (Array.isArray(value)) {
            if (f === 'time' && value.length === 2) {
              return formatTime(value as Time);
            }

            if (f === 'flow' && value.length === 2 && value.every((v) => typeof v === 'number')) {
              return `${value[0]}\u00A0-\u00A0${value[1]} ${getUnit()}`;
            }

            return value
              .map((v: any) => {
                let localizedValue = fm(optsLocPath + v);
                if (v === 'other' && 'endReasonOther' in d) {
                  localizedValue += `${d.endReasonOther ?? '-'}`;
                }
                return localizedValue;
              })
              .join(', ');
          }
          break;
        }
      }
      return undefined;
    };

    return {
      title: fm(locPath + f),
      values: formatValue(),
    };
  });
};

// Used with pap treatment's treatment responses
const deviceMapper = (device: string): string =>
  device === 'autosetForHer'
    ? 'selfAdjustingCPAP'
    : ['selfAdjusting2PV', 'adaptiveServoVentilator', 'automaticAdaptiveServoVentilator'].includes(device)
      ? 'constantPressure2PV'
      : device;

export const convertTreatmentToTimeline = (
  docs: Array<
    | IPatientDoesNotWantRespiratorySupportTherapy
    | IOxygenTherapy
    | IHFNCTherapy
    | IPAPTherapy
    | IMADTherapy
    | ISurgicalTreatment
    | IOtherTreatment
  >,
  resMedDevices: Array<IReadonlyDocument>,
  resMedSettings: Array<IReadonlyDocument>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): Array<IAddon> | undefined => {
  if (docs.length === 0) return undefined;
  const addons: Array<IAddon> = [];
  docs.forEach((d) => {
    switch (d._type) {
      case 'patientDoesNotWantRespiratorySupportTherapy': {
        const doc = d as IPatientDoesNotWantRespiratorySupportTherapy;
        if (!isPartialDate(doc.date)) return;
        const items: IItem[] = [];
        items.push({
          start: dateFromPartialUpdateTimeframe(doc.date),
          end: isPartialDate(doc.endDate) ? dateFromPartialUpdateTimeframe(doc.endDate) : undefined,
          title: fm('treatment.patientDoesNotWantRespiratorySupportTherapy.title'),
        });
        addons.push({
          id: d._type,
          title: fm('treatment.patientDoesNotWantRespiratorySupportTherapy.title'),
          items: items,
          events: [],
        });
        break;
      }
      case 'oxygenTherapy': {
        const doc = d as IOxygenTherapy;
        if (!doc.oxygenTherapyType) return;
        if (!isPartialDate(doc.date)) return;
        const items: IItem[] = [];
        const events: IEvent[] = [];
        items.push({
          start: dateFromPartialUpdateTimeframe(doc.date),
          end: doc.endDate ? dateFromPartialUpdateTimeframe(doc.endDate) : undefined,
          title: fm(
            `treatment.oxygenTherapy.opts.${doc.oxygenTherapyType}${
              doc.oxygenTherapyType === 'stressOxygen' ? 'Short' : ''
            }`,
          ),
          description: createTreatmentTooltipDescription(
            doc,
            ['treatmentStartLocation', 'treatmentStart'],
            'treatment.oxygenTherapy.',
            'treatment.oxygenTherapy.opts.',
            fm,
          ),
        });
        (doc.settings || []).forEach((s: IOxygenTherapySettings) => {
          const sCopy: Omit<IOxygenTherapySettings, 'flow'> & { flow?: number | [number, number] } = structuredClone(s);
          if ('flowMin' in s || 'flowMax' in s) {
            sCopy.flow =
              exists(s.flowMin) && exists(s.flowMax)
                ? [s.flowMin as number, s.flowMax as number]
                : s.flowMin ?? s.flowMax;
          }
          // Description pitää duunata viel
          s.date &&
            events.push({
              date: dateFromPartialUpdateTimeframe(s.date),
              eventType: 'treatment',
              title: fm('treatment.oxygenTherapy.settings.setting'),
              description: createTreatmentTooltipDescription(
                sCopy,
                ['flow', 'additionalInformation', 'implementationOfTreatment', 'humidifierInUse'],
                'treatment.oxygenTherapy.settings.',
                'treatment.oxygenTherapy.opts.',
                fm,
                ['additionalInformation'],
              ),
            });
        });
        (doc.treatmentResponses || []).forEach((s) => {
          // Description pitää duunata viel
          s.date &&
            events.push({
              date: dateFromPartialUpdateTimeframe(s.date),
              eventType: 'treatment',
              title: fm('treatment.oxygenTherapy.treatmentResponses.treatmentResponse'),
              description: createTreatmentTooltipDescription(
                s,
                ['oxygenSaturation'],
                'treatment.oxygenTherapy.treatmentResponses.',
                'treatment.oxygenTherapy.opts.',
                fm,
              ),
            });
        });
        isPartialDate(doc.endDate) &&
          doc.hasEnded?.[0] === true &&
          events.push({
            date: dateFromPartialUpdateTimeframe(doc.endDate),
            eventType: 'treatment',
            title: fm('treatment.treatmentEndedTitle'),
            description: createTreatmentTooltipDescription(
              doc,
              ['endReason'],
              'treatment.',
              'treatment.opts.oxygenTherapyEndReason.',
              fm,
            ),
          });
        addons.push({
          id: doc.oxygenTherapyType,
          title: fm(
            `treatment.oxygenTherapy.opts.${doc.oxygenTherapyType}${
              doc.oxygenTherapyType === 'stressOxygen' ? 'Short' : ''
            }`,
          ),
          titleDescription: undefined,
          items: items,
          events: events,
        });
        break;
      }
      case 'hfncTherapy': {
        const doc = d as IHFNCTherapy;
        if (!isPartialDate(doc.date)) return;
        const items: IItem[] = [];
        const events: IEvent[] = [];
        items.push({
          start: dateFromPartialUpdateTimeframe(doc.date),
          end: doc.endDate ? dateFromPartialUpdateTimeframe(doc.endDate, [23, 59]) : undefined,
          title: fm('treatment.hfncTherapy.title'),
          description: createTreatmentTooltipDescription(
            doc,
            ['treatmentStartLocation', 'treatmentStart'],
            'treatment.hfncTherapy.',
            'treatment.hfncTherapy.opts.',
            fm,
          ),
        });
        (doc.settings || []).forEach((s) => {
          s.date &&
            events.push({
              date: dateFromPartialUpdateTimeframe(s.date, s.time),
              eventType: 'treatment',
              title: fm('treatment.hfncTherapy.settings.setting'),
              description: createTreatmentTooltipDescription(
                s,
                ['time', 'HFNCTreatmentType', 'flowRate', 'supplementalOxygen', 'oxygenFlow'],
                'treatment.hfncTherapy.settings.',
                'treatment.hfncTherapy.settings.',
                fm,
              ),
            });
        });
        isPartialDate(doc.endDate) &&
          doc.hasEnded?.[0] === true &&
          events.push({
            date: dateFromPartialUpdateTimeframe(doc.endDate, [23, 59]),
            eventType: 'treatment',
            title: fm('treatment.treatmentEndedTitle'),
            description: createTreatmentTooltipDescription(
              doc,
              ['endReason'],
              'treatment.',
              'treatment.opts.hfncTherapyEndReason.',
              fm,
            ),
          });
        addons.push({
          id: d._type,
          title: fm('treatment.hfncTherapy.title'),
          titleDescription: undefined,
          items: items,
          events: events,
        });
        break;
      }
      case 'papTherapy': {
        const doc = d as IPAPTherapy;
        if (!isPartialDate(doc.date)) return;
        const items: IItem[] = [];
        const events: IEvent[] = [];
        // Push the bar into items array
        items.push({
          start: dateFromPartialUpdateTimeframe(doc.date),
          end: doc.endDate ? dateFromPartialUpdateTimeframe(doc.endDate) : undefined,
          title: fm('treatment.papTherapy.title'),
          description: createTreatmentTooltipDescription(
            doc,
            ['treatmentStartLocation', 'selfInitiated'],
            'treatment.papTherapy.',
            'treatment.papTherapy.opts.',
            fm,
          ),
        });
        // Push treatment decision date into events array
        isPartialDate(doc.treatmentDecisionDate) &&
          events.push({
            date: dateFromPartialUpdateTimeframe(doc.treatmentDecisionDate),
            eventType: 'treatment',
            title: fm('treatment.papTherapy.treatmentDecisionDate'),
            description: createTreatmentTooltipDescription(
              doc,
              [],
              'treatment.papTherapy.',
              'treatment.papTherapy.opts.',
              fm,
            ),
          });
        // Push devices into events array
        (doc.devices || []).forEach((d) => {
          d.date &&
            events.push({
              date: dateFromPartialUpdateTimeframe(d.date),
              eventType: 'treatment',
              title: fm('treatment.papTherapy.device'),
              description: createTreatmentTooltipDescription(
                d,
                ['type', 'manufacturer', 'deviceModel'],
                'treatment.papTherapy.',
                'treatment.papTherapy.opts.',
                fm,
                ['deviceModel'],
              ),
            });
        });
        // Push settings into events array
        (doc.settings || []).forEach((s) => {
          const targetDevice = Array.isArray(doc.devices) && doc.devices.find((d) => d.id === s.deviceId);
          const deviceType = targetDevice ? targetDevice.type : undefined;

          const fields = (deviceType ? papSettingsFields[deviceType] : []).filter(
            (f) => !f.condition || f.condition(s),
          );

          s.date &&
            events.push({
              date: dateFromPartialUpdateTimeframe(s.date),
              eventType: 'treatment',
              title: fm('treatment.papTherapy.setting'),
              description: createTreatmentTooltipDescription(
                s,
                fields.map((f) => f.name),
                'treatment.papTherapy.',
                'treatment.papTherapy.opts.',
                fm,
                ['trigger', 'cycle', 'mode'],
              ),
            });
        });
        // Push implementation methods into events array
        (doc.implementationMethods || []).forEach((m) => {
          const fields = [
            'implementation',
            'maskBrand',
            'maskSize',
            'additionalInformation',
            'tubeType',
            'tubeName',
            'humidifierInUse',
            'filtersGiven',
          ];
          const filteredFields = m.tubeType !== 'heatTube' ? fields.filter((f) => f !== 'tubeName') : fields;
          m.date &&
            events.push({
              date: dateFromPartialUpdateTimeframe(m.date),
              eventType: 'treatment',
              title: fm('treatment.papTherapy.implementationMethod'),
              description: createTreatmentTooltipDescription(
                m,
                filteredFields,
                'treatment.papTherapy.',
                'treatment.papTherapy.opts.',
                fm,
                ['maskBrand', 'maskSize', 'additionalInformation', 'tubeName', 'filtersGiven'],
              ),
            });
        });

        // Push treatment responses into events array
        if (Array.isArray(doc.treatmentResponses)) {
          doc.treatmentResponses.forEach((t) => {
            // Assign device type related fields into a variable called fields
            const fields = papTreatmentResponseFields[
              deviceMapper((doc.devices || []).find((d) => d.id === t.deviceId)?.type ?? '')
            ]
              .filter((f) => !f.condition || f.condition(t))
              .map((i) => i.name)
              .filter((f) => f !== 'startDateOfPeriodUnderReview')
              .filter((f) => isRecord(t) && (t[f] || t[f] === 0));

            t.date &&
              events.push({
                date: dateFromPartialUpdateTimeframe(t.date),
                eventType: 'treatment',
                title: fm('treatment.papTherapy.treatmentResponse'),
                description: createTreatmentTooltipDescription(
                  t,
                  fields,
                  'treatment.papTherapy.',
                  'treatment.papTherapy.opts.',
                  fm,
                ),
              });
          });
        }
        // Push treatment ending into events array
        isPartialDate(doc.endDate) &&
          doc.hasEnded?.[0] === true &&
          events.push({
            date: dateFromPartialUpdateTimeframe(doc.endDate),
            eventType: 'treatment',
            title: fm('treatment.treatmentEndedTitle'),
            description: createGraphDescriptions<IPAPTherapy>(doc, [
              {
                dataKey: 'endReason',
                localizers: {
                  title: () => fm('treatment.endReason'),
                  values: getPapTherapyEndReasonLocalizer(fm),
                },
              },
              {
                dataKey: 'endReasonOther',
                localizers: {
                  title: () => fm('treatment.endReasonOther'),
                  values: (doc.endReason || []).includes('other') ? (d) => d.endReasonOther || '-' : undefined,
                },
              },
            ]),
          });
        // Push the addon into addons array
        addons.push({
          id: d._type,
          title: fm('treatment.papTherapy.title'),
          titleDescription: undefined,
          items: items,
          events: events,
        });
        break;
      }
      case 'madTherapy': {
        const doc = d as IMADTherapy;
        // If no decision is made about starting treatment, do not do anything
        if (!isPartialDate(doc.treatmentDecisionDate) || !('isTreatmentStarted' in doc)) return;
        const items: IItem[] = [];
        const events: IEvent[] = [];
        if (doc.isTreatmentStarted === false) {
          events.push({
            date: dateFromPartialUpdateTimeframe(doc.treatmentDecisionDate),
            eventType: 'treatment',
            title: fm('treatment.madTherapy.treatmentDecisionDate'),
            description: createTreatmentTooltipDescription(
              doc,
              ['isTreatmentStarted', 'reasonForNotStartingTreatment'],
              'treatment.madTherapy.',
              'treatment.madTherapy.opts.',
              fm,
            ),
          });
        } else {
          doc.date &&
            items.push({
              start: dateFromPartialUpdateTimeframe(doc.date),
              end: doc.endDate ? dateFromPartialUpdateTimeframe(doc.endDate) : undefined,
              title: fm('treatment.madTherapy.title'),
              description: createTreatmentTooltipDescription(
                doc,
                ['treatmentStartLocation'],
                'treatment.madTherapy.',
                'treatment.madTherapy.opts.',
                fm,
              ),
            });
          // Push treatment decision into events array
          isPartialDate(doc.treatmentDecisionDate) &&
            events.push({
              date: dateFromPartialUpdateTimeframe(doc.treatmentDecisionDate),
              eventType: 'treatment',
              title: fm('treatment.madTherapy.treatmentDecisionDate'),
              description: createTreatmentTooltipDescription(
                doc,
                ['isTreatmentStarted'],
                'treatment.madTherapy.',
                'treatment.madTherapy.opts.',
                fm,
              ),
            });
          // Push control visits into events array
          (doc.controls || []).forEach((c) => {
            c.date &&
              events.push({
                date: dateFromPartialUpdateTimeframe(c.date),
                eventType: 'treatment',
                title: fm('treatment.madTherapy.controls.control'),
                description: createTreatmentTooltipDescription(
                  c,
                  [
                    'controlType',
                    'madUsageFrequency',
                    'fatiqueDuringTheDay',
                    'adverseEffects',
                    'details',
                    'deviceAdjustment',
                  ],
                  'treatment.madTherapy.controls.',
                  'treatment.madTherapy.controls.opts.',
                  fm,
                  ['details'],
                ),
              });
          });
          // Push treatment ending into events array
          isPartialDate(doc.endDate) &&
            doc.hasEnded?.[0] === true &&
            events.push({
              date: dateFromPartialUpdateTimeframe(doc.endDate),
              eventType: 'treatment',
              title: fm('treatment.treatmentEndedTitle'),
              description: createTreatmentTooltipDescription(
                doc,
                ['endReason'],
                'treatment.',
                'treatment.opts.madTherapyEndReason.',
                fm,
              ),
            });
        }
        addons.push({
          id: d._type,
          title: fm('treatment.madTherapy.title'),
          titleDescription: undefined,
          items: items,
          events: events,
        });
        break;
      }
      case 'surgicalTreatment': {
        const doc = d as ISurgicalTreatment;
        if (!isPartialDate(doc.date) && !isPartialDate(doc.treatmentDecisionDate)) return;
        const events: IEvent[] = [];
        isPartialDate(doc.treatmentDecisionDate) &&
          events.push({
            date: dateFromPartialUpdateTimeframe(doc.treatmentDecisionDate),
            eventType: 'treatment',
            title: fm('treatment.surgicalTreatment.treatmentDecision'),
          });
        const descriptionFields: string[] =
          doc.operationType === 'bariatricSurgery'
            ? ['operationType', 'bariatricSurgeryType', 'primaryOrReoperation', 'reoperationNeed']
            : doc.operationType === 'knkSurgery'
              ? ['operationType', 'knkType']
              : ['operationType'];
        isPartialDate(doc.date) &&
          events.push({
            date: dateFromPartialUpdateTimeframe(doc.date),
            eventType: 'treatment',
            title: fm('treatment.surgicalTreatment.operation'),
            description: createTreatmentTooltipDescription(
              doc,
              descriptionFields,
              'treatment.surgicalTreatment.',
              'treatment.surgicalTreatment.opts.',
              fm,
            ),
          });
        // control visits (knk surgery)
        (doc.controlVisits || []).forEach((cv) => {
          if (!isPartialDate(cv.date)) return;
          const descFields = [
            'controlType',
            'patientPleasedWithSurgery',
            'pleasedInformation',
            'dayTimeTiredness',
            'monitoringPolygraphyDone',
            'treatmentComplication',
          ];
          if (cv.treatmentComplication === 'yes') descFields.push('complicationInformation');
          const desc = createTreatmentTooltipDescription(
            cv,
            descFields,
            'treatment.surgicalTreatment.controls.',
            'treatment.surgicalTreatment.controls.opts.',
            fm,
            ['pleasedInformation', 'complicationInformation'],
          );
          events.push({
            date: dateFromPartialUpdateTimeframe(cv.date),
            eventType: 'controlVisit',
            title: fm('treatment.surgicalTreatment.controls.control'),
            description: desc,
          });
        });
        // jaw surgery control visits
        (doc.controlVisitsJawSurgery || []).forEach((cv) => {
          if (!isPartialDate(cv.date)) return;
          const descFields = ['controlType', 'dayTimeTiredness'];
          const desc = createTreatmentTooltipDescription(
            cv,
            descFields,
            'treatment.surgicalTreatment.controls.',
            'treatment.surgicalTreatment.controls.opts.',
            fm,
            ['pleasedInformation', 'complicationInformation'],
          );
          events.push({
            date: dateFromPartialUpdateTimeframe(cv.date),
            eventType: 'controlVisit',
            title: fm('treatment.surgicalTreatment.controls.control'),
            description: desc,
          });
        });
        addons.push({
          id: doc.operationType || d._type,
          title: doc.operationType
            ? fm(`treatment.surgicalTreatment.opts.${doc.operationType}`)
            : fm('treatment.surgicalTreatment.title'),
          items: undefined,
          events: events,
        });
        break;
      }
      case 'otherTreatment': {
        const doc = d as IOtherTreatment;
        if (!isPartialDate(doc.date)) return;
        const items: IItem[] = [];
        switch (doc.otherTreatmentType) {
          case 'postureTreatment': {
            items.push({
              start: dateFromPartialUpdateTimeframe(doc.date),
              end: isPartialDate(doc.endDate) ? dateFromPartialUpdateTimeframe(doc.endDate) : undefined,
              title: fm('treatment.otherTreatment.opts.postureTreatment'),
              description: doc.otherTreatmentDetails,
            });
            addons.push({
              id: doc.otherTreatmentType,
              title: fm('treatment.otherTreatment.opts.postureTreatment'),
              items: items,
              events: [],
            });
            break;
          }
          case 'lifestyleTreatment': {
            items.push({
              start: dateFromPartialUpdateTimeframe(doc.date),
              end: undefined,
              title: fm('treatment.otherTreatment.opts.lifestyleTreatment'),
              description: doc.otherTreatmentDetails,
            });
            addons.push({
              id: doc.otherTreatmentType,
              title: fm('treatment.otherTreatment.opts.lifestyleTreatment'),
              items: items,
              events: [],
            });
            break;
          }
          case 'referralToNutritionist': {
            const events: IEvent[] = [];
            events.push({
              date: dateFromPartialUpdateTimeframe(doc.date),
              eventType: 'treatment',
              title: fm('treatment.otherTreatment.opts.referralToNutritionist'),
              description: doc.otherTreatmentDetails,
            });
            addons.push({
              id: doc.otherTreatmentType,
              title: fm('treatment.otherTreatment.opts.referralToNutritionist'),
              items: undefined,
              events: events,
            });
            break;
          }
          case 'referralToWeightControl': {
            const events: IEvent[] = [];
            events.push({
              date: dateFromPartialUpdateTimeframe(doc.date),
              eventType: 'treatment',
              title: fm('treatment.otherTreatment.opts.referralToWeightControl'),
              description: doc.otherTreatmentDetails,
            });
            addons.push({
              id: doc.otherTreatmentType,
              title: fm('treatment.otherTreatment.opts.referralToWeightControl'),
              items: undefined,
              events: events,
            });
            break;
          }
          case 'psychiatristConsultation': {
            const events: IEvent[] = [];
            events.push({
              date: dateFromPartialUpdateTimeframe(doc.date),
              eventType: 'treatment',
              title: fm('treatment.otherTreatment.opts.psychiatristConsultation'),
              description: doc.otherTreatmentDetails,
            });
            addons.push({
              id: doc.otherTreatmentType,
              title: fm('treatment.otherTreatment.opts.psychiatristConsultation'),
              items: undefined,
              events: events,
            });
            break;
          }
          default: {
            items.push({
              start: dateFromPartialUpdateTimeframe(doc.date),
              end: isPartialDate(doc.endDate) ? dateFromPartialUpdateTimeframe(doc.endDate) : undefined,
              title: fm('treatment.otherTreatment.title'),
              description: doc.otherTreatmentDetails,
            });
            addons.push({
              id: d._type,
              title: fm('treatment.otherTreatment.title'),
              items: items,
              events: [],
            });
            break;
          }
        }
      }
    }
  });
  resMedDevices.forEach((rd) => {
    if (!isPartialDate(rd.date)) return;
    const events: IEvent[] = [];
    events.push({
      date: dateFromPartialUpdateTimeframe(rd.date),
      eventType: 'treatment',
      title: fm('treatment.papTherapy.device'),
      description: createTreatmentTooltipDescription(
        rd,
        ['type', 'manufacturer', 'deviceModel'],
        'treatment.papTherapy.',
        'treatment.papTherapy.opts.',
        fm,
        ['deviceModel'],
      ),
    });

    addons.push({
      id: 'papTherapy',
      title: fm('treatment.papTherapy.title'),
      titleDescription: undefined,
      events: events,
    });
  });
  resMedSettings.forEach((rs) => {
    if (!isPartialDate(rs.date)) return;
    const events: IEvent[] = [];

    const settingsFields = Object.keys(rs)?.filter((k) => !['_id', '_type', '_source', 'id', 'date'].includes(k));

    events.push({
      date: dateFromPartialUpdateTimeframe(rs.date),
      eventType: 'treatment',
      title: fm('treatment.papTherapy.setting'),
      description: createTreatmentTooltipDescription(
        rs,
        settingsFields,
        'treatment.papTherapy.',
        'treatment.papTherapy.opts.',
        fm,
        ['trigger', 'cycle', 'mode'],
      ),
    });

    addons.push({
      id: 'papTherapy',
      title: fm('treatment.papTherapy.title'),
      titleDescription: undefined,
      events: events,
    });
  });
  return addons.length > 0 ? addons : undefined;
};

const getClpAndCnpTestsUnit = (fieldName: string, fm: (id: string) => string): string | undefined => {
  const units: { [key: string]: string } = {
    tcCO2max: 'clpAndCnpTests.kPa',
    tcCO2mean: 'clpAndCnpTests.kPa',
    tcCO2min: 'clpAndCnpTests.kPa',
    saO295thPercentile: '%',
    saO2Median: '%',
    saO2Minimum: '%',
    saO2GreaterThan88: '%',
    saO2LessThan88: 'clpAndCnpTests.minutes',
    odi4: 'clpAndCnpTests.perHour',
    pulse: 'clpAndCnpTests.bpm',
    ahi: 'clpAndCnpTests.perHour',
    ahiSupine: 'clpAndCnpTests.perHour',
    rei: 'clpAndCnpTests.perHour',
    reiSupine: 'clpAndCnpTests.perHour',
    odi3: 'clpAndCnpTests.perHour',
    cai: 'clpAndCnpTests.perHour',
    averageSpO2: '%',
    spO2Below90: 'clpAndCnpTests.percentsOfNight',
    lowestSpO2: '%',
    respiratoryRate: 'clpAndCnpTests.perMinute',
    plmi: 'clpAndCnpTests.perHour',
    plm: '%',
    snoring: 'clpAndCnpTests.snoringUnit',
    tib: 'clpAndCnpTests.minutes',
    tst: 'clpAndCnpTests.minutes',
    se: '%',
    waso: 'clpAndCnpTests.minutes',
    sl: 'clpAndCnpTests.minutes',
    numberOfSleepCycles: 'clpAndCnpTests.perNight',
    n1: '%',
    n2: '%',
    n3: '%',
    rem: '%',
    wakeUpIndex: 'clpAndCnpTests.perHour',
    saO2LessThan88Percents: '%',
    saO2LessThan88Minutes: 'clpAndCnpTests.minutes',
    vcLiters: fm('clpAndCnpTests.liters'),
    vcZValue: fm('clpAndCnpTests.zValue'),
    fvcLiters: fm('clpAndCnpTests.liters'),
    fvcZValue: fm('clpAndCnpTests.zValue'),
    fev1Liters: fm('clpAndCnpTests.liters'),
    fev1ZValue: fm('clpAndCnpTests.zValue'),
    fevPercents: '%',
    fevPercentsZValue: fm('clpAndCnpTests.zValue'),
    cheyneStokesRespirationMinutes: 'clpAndCnpTests.minutes',
    cheyneStokesRespirationPercents: '%',
  };
  return fieldName in units && units[fieldName].includes('.')
    ? fm(units[fieldName])
    : fieldName in units
      ? units[fieldName]
      : undefined;
};

const createClpAndCnpTestsTooltipDescription = (
  d: ISleepPolygraphy | ISleepStudy | ISpirometry | INightOximetry | ICarbonDioxideMeasurement,
  fields: string[],
  locPath: string,
  fm: (id: string) => string,
): IEvent['description'] => {
  return fields.map((field) => {
    const f = field as keyof (
      | ISleepPolygraphy
      | ISleepStudy
      | ISpirometry
      | INightOximetry
      | ICarbonDioxideMeasurement
    );
    return {
      title: field === 'madTherapyInUse' ? fm(locPath + 'madTherapyInUse') : fm(locPath + f),
      values:
        typeof d[f] === 'string'
          ? f in d
            ? fm(`${locPath}opts.sleepPolygraphy.${d[f]}`)
            : undefined
          : `${d[f] ?? '-'} ${getClpAndCnpTestsUnit(f, fm) ?? ''}`,
    };
  });
};

const isMadUseWanted = (mads: IMADTherapy[], date: PartialDate): boolean => {
  const dateValue = partialDateToValue(date);
  const boolArr = mads.map((md) => {
    const startDateValue = md.date ? partialDateToValue(md.date) : 0;
    const endDateValue = md.endDate ? partialDateToValue(md.endDate) : Number.MAX_SAFE_INTEGER;
    if (startDateValue <= dateValue && dateValue <= endDateValue) {
      return true;
    } else {
      return false;
    }
  });
  if (boolArr.find((ele) => ele === true)) return true;
  else return false;
};

export const convertClpAndCnpTestsToTimeline = (
  docs: Array<ISleepPolygraphy | ISleepStudy | ISpirometry | INightOximetry | ICarbonDioxideMeasurement>,
  madDocs: Array<IMADTherapy>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): Array<IAddon> | undefined => {
  if (docs.length === 0) return undefined;
  const addons: IAddon[] = [];
  docs.forEach((d) => {
    switch (d._type) {
      case 'sleepPolygraphy': {
        const doc = d as ISleepPolygraphy;
        if (!isPartialDate(doc.date)) return;
        const events: IEvent[] = [];
        const extraField = isMadUseWanted(madDocs, doc.date) ? ['madTherapyInUse'] : [];
        const defaultFields = [
          'ahi',
          'ahiSupine',
          'rei',
          'reiSupine',
          'odi3',
          'cai',
          'averageSpO2',
          'spO2Below90',
          'lowestSpO2',
          'respiratoryRate',
          'tcCo2Max',
          'tcCo2Min',
          'incidenceOfLongTermStenosis',
          'flowRestrictionPercentage',
          'plmi',
          'plm',
        ];
        const wantedFields = extraField.concat(defaultFields);
        // if flowRestriction is found, insert that field field before plmi field
        doc.flowRestriction ? wantedFields.splice(wantedFields.length - 1, 0, 'flowRestriction') : undefined;

        doc.date &&
          events.push({
            date: dateFromPartialUpdateTimeframe(doc.date),
            eventType: 'study',
            title: fm('clpAndCnpTests.sleepPolygraphy'),
            description: createClpAndCnpTestsTooltipDescription(doc, wantedFields, 'clpAndCnpTests.', fm),
          });
        addons.push({
          id: d._type,
          title: fm('clpAndCnpTests.sleepPolygraphy'),
          titleDescription: undefined,
          items: [],
          events: events,
        });
        break;
      }
      case 'sleepStudy': {
        const doc = d as ISleepStudy;
        if (!isPartialDate(doc.date)) return;
        const events: IEvent[] = [];
        const extraField = isMadUseWanted(madDocs, doc.date) ? ['madTherapyInUse'] : [];
        const defaultFields = [
          'ahi',
          'ahiSupine',
          'odi3',
          'cai',
          'averageSpO2',
          'spO2Below90',
          'lowestSpO2',
          'respiratoryRate',
          'snoring',
          'incidenceOfLongTermStenosis',
          'tib',
          'tst',
          'se',
          'waso',
          'sl',
          'numberOfSleepCycles',
          'soremp',
          'n1',
          'n2',
          'n3',
          'rem',
          'wakeUpIndex',
          'plmi',
          'plm',
        ];
        const wantedFields = extraField.concat(defaultFields);
        doc.date &&
          events.push({
            date: dateFromPartialUpdateTimeframe(doc.date),
            eventType: 'study',
            title: fm('clpAndCnpTests.sleepStudy'),
            description: createClpAndCnpTestsTooltipDescription(doc, wantedFields, 'clpAndCnpTests.', fm),
          });
        addons.push({
          id: d._type,
          title: fm('clpAndCnpTests.sleepStudy'),
          titleDescription: undefined,
          items: [],
          events: events,
        });
        break;
      }
      case 'spirometry': {
        const doc = d as ISpirometry;
        if (!isPartialDate(doc.date)) return;
        const events: IEvent[] = [];
        const dataFields: Array<keyof ISpirometry> = [
          'vcLiters',
          'vcZValue',
          'fvcLiters',
          'fvcZValue',
          'fev1Liters',
          'fev1ZValue',
          'fevPercents',
          'fevPercentsZValue',
        ];
        doc.date &&
          events.push({
            date: dateFromPartialUpdateTimeframe(doc.date),
            eventType: 'study',
            title: fm('clpAndCnpTests.spirometry'),
            description: createClpAndCnpTestsTooltipDescription(
              doc,
              dataFields,
              'clpAndCnpTests.graphLocalizations.',
              fm,
            ),
          });
        addons.push({
          id: d._type,
          title: fm('clpAndCnpTests.spirometry'),
          titleDescription: undefined,
          items: [],
          events: events,
        });
        break;
      }
      case 'nightOximetry': {
        const doc = d as INightOximetry;
        if (!isPartialDate(doc.date)) return;
        const events: IEvent[] = [];

        const dataFields: Array<keyof INightOximetry> = [
          'saO295thPercentile',
          'saO2Median',
          'saO2Minimum',
          'saO2LessThan88Percents',
          'saO2LessThan88Minutes',
          'odi4',
          'pulse',
          'cheyneStokesRespirationMinutes',
          'cheyneStokesRespirationPercents',
        ];

        doc.date &&
          events.push({
            date: dateFromPartialUpdateTimeframe(doc.date),
            eventType: 'study',
            title: fm('clpAndCnpTests.nightOximetry'),
            description: createClpAndCnpTestsTooltipDescription(doc, dataFields, 'clpAndCnpTests.', fm),
          });
        addons.push({
          id: d._type,
          title: fm('clpAndCnpTests.nightOximetry'),
          titleDescription: undefined,
          items: [],
          events: events,
        });
        break;
      }
      case 'carbonDioxideMeasurement': {
        const doc = d as ICarbonDioxideMeasurement;
        if (!doc) return;
        if (!isPartialDate(doc.date)) return;
        const events: IEvent[] = [];
        doc.date &&
          events.push({
            date: dateFromPartialUpdateTimeframe(doc.date),
            eventType: 'study',
            title: fm('clpAndCnpTests.carbonDioxideMeasurement'),
            description: createClpAndCnpTestsTooltipDescription(
              doc,
              ['tcCO2max', 'tcCO2mean', 'tcCO2min'],
              'clpAndCnpTests.',
              fm,
            ),
          });
        addons.push({
          id: d._type,
          title: fm('clpAndCnpTests.carbonDioxideMeasurement'),
          titleDescription: undefined,
          items: [],
          events: events,
        });
        break;
      }
    }
  });
  return addons.length > 0 ? addons : undefined;
};

export const convertPapToGraph = (
  docs: IPAPTherapy[],
  resMedTreatmentResponses: Array<IReadonlyDocument>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): Array<IData> | undefined => {
  if (docs.length === 0 && resMedTreatmentResponses.length === 0) return undefined;
  const primaryDataPoints: IDataPoint[] = [];
  const primaryData: IData[] = [];
  const resMedDataPoints: IDataPoint[] = [];
  docs.forEach((d) => {
    (d.treatmentResponses || []).forEach((tr) => {
      const value =
        Math.round(((tr.patientHoursOfUsePerDay ? tr.patientHoursOfUsePerDay : 0) + Number.EPSILON) * 100) / 100;
      exists(tr.patientHoursOfUsePerDay) &&
        tr.startDateOfPeriodUnderReview &&
        tr.date &&
        primaryDataPoints.push({
          date: dateFromPartialUpdateTimeframe(tr.date),
          value: value,
          id: 'pap',
          endDate: dateFromPartialUpdateTimeframe(tr.startDateOfPeriodUnderReview),
          title: `${value}`,
        });
    });
  });
  resMedTreatmentResponses.forEach((rtr) => {
    if (!isPartialDate(rtr.date) || !isNumber(rtr.patientHoursOfUsePerDay)) return;
    resMedDataPoints.push({
      date: dateFromPartialUpdateTimeframe(rtr.date),
      value: rtr.patientHoursOfUsePerDay,
      id: 'resMedPap',
      title: `${rtr.patientHoursOfUsePerDay},`,
    });
  });

  primaryDataPoints &&
    primaryDataPoints.length > 0 &&
    primaryData.push({
      dataPoints: primaryDataPoints,
      id: 'pap',
      type: 'constantValueChart',
      legend: fm('treatment.papTherapy.patientHoursOfUsePerDay'),
    });

  resMedDataPoints.length > 0 &&
    primaryData.push({
      dataPoints: resMedDataPoints,
      id: 'resMedPap',
      type: 'lineGraph',
      legend: `${fm('treatment.papTherapy.patientHoursOfUsePerDay')} (ResMed)`,
      linegraphProps: {
        hidePoints: true,
      },
    });

  return primaryData.length > 0 ? primaryData : undefined;
};

const getDiagnosisDocuments = (docs: Array<IControlProps>): Array<IDiagnosis> => {
  const dgDocs: Array<IDiagnosis> = [];
  docs
    .filter((d) => d._type === 'diagnosis')
    .forEach((d) => {
      dgDocs.push(d);
    });
  return dgDocs;
};

export const convertAHIToGraph = (
  papTherapyDocs: Array<IPAPTherapy>,
  sleepPolygraphyDocs: Array<ISleepPolygraphy>,
  sleepStudyDocs: Array<ISleepStudy>,
  resMedTreatmentResponses: Array<IReadonlyDocument>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): Array<IData> | undefined => {
  if (
    papTherapyDocs.length === 0 &&
    sleepPolygraphyDocs.length === 0 &&
    sleepStudyDocs.length === 0 &&
    resMedTreatmentResponses.length === 0
  )
    return undefined;
  // ResMed AHI datapoints
  const resMedAhiDataPoints: IDataPoint[] = [];
  // AHI datapoints
  const ahiDataPoints: IDataPoint[] = [];
  // REI datapoints
  const reiDataPoints: IDataPoint[] = [];
  // Secondarydata for residue ahi
  const secondaryDataPoints: IDataPoint[] = [];
  // tertiarydata for residue cai
  const tertiaryDataPoints: IDataPoint[] = [];
  const data: IData[] = [];

  // Handle ResMed docs
  resMedTreatmentResponses.forEach((rtr) => {
    if (!isPartialDate(rtr.date) || !isNumber(rtr.ahi)) return;
    resMedAhiDataPoints.push({
      date: dateFromPartialUpdateTimeframe(rtr.date),
      value: rtr.ahi,
      id: 'resMedAhi',
      title: `${rtr.ahi} / h`,
    });
  });

  // Handle pap therapy docs here
  papTherapyDocs.forEach((d) => {
    if (!isPartialDate(d.date)) return;
    (d.treatmentResponses || []).forEach((tr) => {
      (tr.ahi || tr.ahi === 0) &&
        tr.date &&
        tr.startDateOfPeriodUnderReview &&
        secondaryDataPoints.push({
          date: dateFromPartialUpdateTimeframe(tr.date),
          value: tr.ahi,
          id: 'residueAhi',
          endDate: dateFromPartialUpdateTimeframe(tr.startDateOfPeriodUnderReview),
          title: `${tr.ahi}`,
        });
    });
  });
  // Handle sleep polygraphy docs here
  sleepPolygraphyDocs.forEach((d) => {
    if (!isPartialDate(d.date)) return;
    const valueAhi = d.ahi || d.ahi === 0 ? d.ahi : undefined;
    const valueRei = d.rei || d.rei === 0 ? d.rei : undefined;
    valueAhi &&
      d.date &&
      ahiDataPoints.push({
        date: dateFromPartialUpdateTimeframe(d.date),
        value: valueAhi,
        id: 'ahi',
        endDate: dateFromPartialUpdateTimeframe(d.date),
        title: `${valueAhi}`,
        description: [{ title: fm('clpAndCnpTests.sleepPolygraphy') }],
      });
    valueRei &&
      d.date &&
      reiDataPoints.push({
        date: dateFromPartialUpdateTimeframe(d.date),
        value: valueRei,
        id: 'rei',
        endDate: dateFromPartialUpdateTimeframe(d.date),
        title: `${valueRei}`,
        description: [{ title: fm('clpAndCnpTests.sleepPolygraphy') }],
      });
  });
  // Handle sleep study docs here
  sleepStudyDocs.forEach((d) => {
    if (!isPartialDate(d.date)) return;
    const value = d.ahi || d.ahi === 0 ? d.ahi : undefined;
    value &&
      d.date &&
      ahiDataPoints.push({
        date: dateFromPartialUpdateTimeframe(d.date),
        value: value,
        id: 'ahi',
        endDate: dateFromPartialUpdateTimeframe(d.date),
        title: `${value}`,
        description: [{ title: fm('clpAndCnpTests.sleepStudy') }],
      });
  });
  tertiaryDataPoints &&
    tertiaryDataPoints.length > 0 &&
    data.unshift({
      dataPoints: tertiaryDataPoints,
      id: 'residueCai',
      type: 'constantValueChart',
      legend: fm('graph.residueCai'),
    });
  secondaryDataPoints &&
    secondaryDataPoints.length > 0 &&
    data.unshift({
      dataPoints: secondaryDataPoints,
      id: 'residueAhi',
      type: 'constantValueChart',
      legend: fm('graph.residueAhi'),
    });
  ahiDataPoints &&
    ahiDataPoints.length > 0 &&
    data.unshift({
      dataPoints: ahiDataPoints,
      id: 'ahi',
      type: 'lineGraph',
      legend: fm('clpAndCnpTests.ahi'),
    });
  reiDataPoints &&
    reiDataPoints.length > 0 &&
    data.unshift({
      dataPoints: reiDataPoints,
      id: 'rei',
      type: 'lineGraph',
      legend: fm('clpAndCnpTests.rei'),
    });
  resMedAhiDataPoints &&
    resMedAhiDataPoints.length > 0 &&
    data.unshift({
      dataPoints: resMedAhiDataPoints,
      id: 'resMedAhi',
      type: 'lineGraph',
      legend: `${fm('clpAndCnpTests.ahi')} (ResMed)`,
      linegraphProps: {
        hidePoints: true,
      },
    });
  return data.length > 0 ? data : undefined;
};

export const convert95thPercentilePressureToGraph = (
  docs: Array<IPAPTherapy>,
  allDocs: Array<IControlProps>,
  resMedTreatmentResponses: Array<IReadonlyDocument>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): Array<IData> | undefined => {
  const hasSleepApnea = patientHasSleepApneaDiagnosis(getDiagnosisDocuments(allDocs));
  // If patient didnt have self adjusting cpap device, do not draw anything
  const hasSelfAdjustingCPAPDevice = !!docs.find((d) => {
    if ((d.devices || []).find((dev) => dev.type === 'selfAdjustingCPAP' || dev.type === 'autosetForHer')) return true;
    return false;
  });
  if (
    (docs.length === 0 && resMedTreatmentResponses.length === 0) ||
    !hasSleepApnea ||
    (!hasSelfAdjustingCPAPDevice && resMedTreatmentResponses.length === 0)
  ) {
    return undefined;
  }
  const data: IData[] = [];
  const primaryDataPoints: IDataPoint[] = [];
  const values: { startDate: PartialDate; endDate: PartialDate; value: number }[] = [];

  const resMedDataPoints: IDataPoint[] = [];

  docs.forEach((d) => {
    // Push pressure-values into a variable called 'values'
    (d.treatmentResponses || []).forEach((tr) => {
      tr.startDateOfPeriodUnderReview &&
        tr.date &&
        isPartialDate(tr.startDateOfPeriodUnderReview) &&
        isPartialDate(tr.date) &&
        exists(tr.pressure95Percentile) &&
        values.push({
          startDate: tr.startDateOfPeriodUnderReview,
          endDate: tr.date,
          value: tr.pressure95Percentile ?? 0,
        });
    });
  });
  // Iterate through the values array and make a datapoint out of each element of the values-array
  values.forEach((v) => {
    primaryDataPoints.push({
      date: dateFromPartialUpdateTimeframe(v.endDate),
      value: v.value,
      id: 'pressure95thPercentile',
      endDate: dateFromPartialUpdateTimeframe(v.startDate),
      title: `${v.value} cmH2O`,
    });
  });
  primaryDataPoints &&
    primaryDataPoints.length > 0 &&
    data.push({
      dataPoints: primaryDataPoints,
      id: 'pressure95thPercentile',
      type: 'constantValueChart',
      legend: fm('treatment.papTherapy.pressure95Percentile'),
    });

  resMedTreatmentResponses.forEach((tr) => {
    tr.pressure95Percentile &&
      resMedDataPoints.push({
        date: dateFromPartialUpdateTimeframe(tr.date),
        value: tr.pressure95Percentile,
        id: 'resMedPressure95Percentile',
        title: `${tr.pressure95Percentile} cmH2O`,
      });
  });

  resMedDataPoints.length > 0 &&
    data.push({
      dataPoints: resMedDataPoints,
      id: 'resMedPressure95Percentile',
      type: 'lineGraph',
      legend: fm('treatment.papTherapy.pressure95Percentile'),
      linegraphProps: {
        hidePoints: true,
      },
    });

  return data.length > 0 ? data : undefined;
};

export const convert95thPercentileMaskLeakToGraph = (
  docs: Array<IPAPTherapy>,
  resMedTreatmentResponses: Array<IReadonlyDocument>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): Array<IData> | undefined => {
  if (docs.length === 0 && resMedTreatmentResponses.length === 0) return undefined;
  const data: IData[] = [];
  const primaryDataPoints: IDataPoint[] = [];
  const resMedDataPoints: IDataPoint[] = [];

  docs.forEach((d) => {
    if (!isPartialDate(d.date)) return;
    (d.treatmentResponses || []).forEach((tr) => {
      (tr.maskLeakage95PercentilePerMinute || tr.maskLeakage95PercentilePerMinute === 0) &&
        tr.startDateOfPeriodUnderReview &&
        tr.date &&
        primaryDataPoints.push({
          date: dateFromPartialUpdateTimeframe(tr.date),
          value: tr.maskLeakage95PercentilePerMinute,
          id: 'maskLeak95thPercentile',
          endDate: dateFromPartialUpdateTimeframe(tr.startDateOfPeriodUnderReview),
          title: `${tr.maskLeakage95PercentilePerMinute} l/min`,
        });
    });
  });
  primaryDataPoints &&
    primaryDataPoints.length > 0 &&
    data.push({
      dataPoints: primaryDataPoints,
      id: 'maskLeak95thPercentile',
      type: 'constantValueChart',
      legend: fm('treatment.papTherapy.maskLeakage95PercentilePerMinute'),
    });

  resMedTreatmentResponses.forEach((rtr) => {
    if (!isPartialDate(rtr.date) || !isNumber(rtr.maskLeakage95PercentilePerMinute)) return;
    resMedDataPoints.push({
      date: dateFromPartialUpdateTimeframe(rtr.date),
      value: rtr.maskLeakage95PercentilePerMinute,
      id: 'resMedMaskLeak95Percentile',
      title: `${rtr.maskLeakage95PercentilePerMinute} l/min`,
    });
  });

  resMedDataPoints.length > 0 &&
    data.push({
      dataPoints: resMedDataPoints,
      id: 'resMedMaskLeak95Percentile',
      type: 'lineGraph',
      legend: `${fm('treatment.papTherapy.maskLeakage95PercentilePerMinute')} (ResMed)`,
      linegraphProps: {
        hidePoints: true,
      },
    });

  return data.length > 0 ? data : undefined;
};

export const convertTcCO2MeanToGraph = (
  docs: Array<ICarbonDioxideMeasurement>,
  allDocs: Array<IControlProps>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): Array<IData> | undefined => {
  const hasRespiratoryFailure = patientHasRespiratoryFailureDiagnosis(getDiagnosisDocuments(allDocs));
  if (docs.length === 0 || !hasRespiratoryFailure) return undefined;
  const primaryData: IData[] = [];
  const primaryDataPoints: IDataPoint[] = [];
  docs.forEach((d) => {
    if (!isPartialDate(d.date) || !exists(d.tcCO2mean)) return;
    d.date &&
      exists(d.tcCO2mean) &&
      primaryDataPoints.push({
        date: dateFromPartialUpdateTimeframe(d.date),
        value: d.tcCO2mean ?? 0,
        id: 'tcCO2Mean',
        title: `${d.tcCO2mean ?? '-'} ${fm('clpAndCnpTests.kPa')}`,
      });
  });
  primaryDataPoints &&
    primaryDataPoints.length > 0 &&
    primaryData.push({
      dataPoints: primaryDataPoints,
      id: 'tcCO2Mean',
      type: 'lineGraph',
      legend: fm('clpAndCnpTests.tcCO2mean'),
    });
  return primaryData.length > 0 ? primaryData : undefined;
};

export const convertOxygenFlowToGraph = (
  oxygenTherapyDocs: Array<IOxygenTherapy>,
  allDocs: Array<IControlProps>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): Array<IData> | undefined => {
  const hasRespiratoryFailure = patientHasRespiratoryFailureDiagnosis(getDiagnosisDocuments(allDocs));
  if (oxygenTherapyDocs.length === 0 || !hasRespiratoryFailure) return undefined;
  const data: IData[] = [];

  ['oxygenConcentratorTherapeutic', 'oxygenConcentratorPalliative', 'stressOxygen'].forEach((t, i) => {
    const primaryDataPoints: IDataPoint[] = [];
    const secondaryDataPoints: IDataPoint[] = [];
    const primaryDataPointsPercentage: IDataPoint[] = [];
    const secondaryDataPointsPercentage: IDataPoint[] = [];

    oxygenTherapyDocs.forEach((d) => {
      if (!d || !d.oxygenTherapyType || d.oxygenTherapyType !== t) return;
      if (!isPartialDate(d.date)) return;
      const settings = d.settings ? [...d.settings] : [];
      settings
        .sort((s1, s2) => sortPartialDate(s1.date, s2.date))
        .forEach((s, i) => {
          const endDate: PartialDate | undefined = settings[i + 1]?.date || d.endDate || undefined;

          if (s.date && (exists(s.flowMin) || exists(s.flowMax))) {
            const common = {
              date: dateFromPartialUpdateTimeframe(s.date),
              endDate: endDate ? dateFromPartialUpdateTimeframe(endDate) : undefined,
            };

            switch (s.implementationOfTreatment) {
              case 'venturiMask': {
                if (exists(s.flowMin)) {
                  primaryDataPointsPercentage.push({
                    ...common,
                    value: s.flowMin!,
                    id: 'oxygenFlowPercent',
                    title: `${s.flowMin} %`,
                  });
                }
                if (exists(s.flowMax)) {
                  secondaryDataPointsPercentage.push({
                    ...common,
                    value: s.flowMax!,
                    id: 'oxygenFlowPercentMax',
                    title: `${s.flowMax} %`,
                  });
                }
                break;
              }
              default: {
                if (exists(s.flowMin)) {
                  primaryDataPoints.push({
                    ...common,
                    value: s.flowMin!,
                    id: 'oxygenFlow',
                    title: `${s.flowMin} l/min`,
                  });
                }
                if (exists(s.flowMax)) {
                  secondaryDataPoints.push({
                    ...common,
                    value: s.flowMax!,
                    id: 'oxygenFlowMax',
                    title: `${s.flowMax} l/min`,
                  });
                }
                break;
              }
            }
          }
        });
    });

    primaryDataPoints &&
      primaryDataPoints.length > 0 &&
      data.push({
        dataPoints: primaryDataPoints,
        id: `oxygenFlow${i}`,
        type: 'constantValueChart',
        legend:
          fm('graph.oxygenFlow') +
          ` ${fm('general.minimum')}`.toLocaleLowerCase() +
          ` (${fm(`treatment.oxygenTherapy.opts.${t}`)})`,
      });
    secondaryDataPoints &&
      secondaryDataPoints.length > 0 &&
      data.push({
        dataPoints: secondaryDataPoints,
        id: `oxygenFlowMax${i}`,
        type: 'constantValueChart',
        legend:
          fm('graph.oxygenFlow') +
          ` ${fm('general.maximum')}`.toLocaleLowerCase() +
          ` (${fm(`treatment.oxygenTherapy.opts.${t}`)})`,
      });

    primaryDataPointsPercentage &&
      primaryDataPointsPercentage.length > 0 &&
      data.push({
        dataPoints: primaryDataPointsPercentage,
        id: `oxygenFlowPercent${i}`,
        type: 'constantValueChart',
        legend:
          fm('graph.oxygenFlow') +
          ` ${fm('general.minimum')}`.toLocaleLowerCase() +
          ` (${fm(`treatment.oxygenTherapy.opts.${t}`)})`,
      });
    secondaryDataPointsPercentage &&
      secondaryDataPointsPercentage.length > 0 &&
      data.push({
        dataPoints: secondaryDataPointsPercentage,
        id: `oxygenFlowPercentMax${i}`,
        type: 'constantValueChart',
        legend:
          fm('graph.oxygenFlow') +
          ` ${fm('general.maximum')}`.toLocaleLowerCase() +
          ` (${fm(`treatment.oxygenTherapy.opts.${t}`)})`,
      });
  });
  return data.length > 0 ? data : undefined;
};

export const convertOxygenSaturationToGraph = (
  docs: Array<IOxygenTherapy>,
  allDocs: Array<IControlProps>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): Array<IData> | undefined => {
  const hasRespiratoryFailure = patientHasRespiratoryFailureDiagnosis(getDiagnosisDocuments(allDocs));
  if (docs.length === 0 || !hasRespiratoryFailure) return undefined;
  const data: IData[] = [];

  ['oxygenConcentratorTherapeutic', 'oxygenConcentratorPalliative', 'stressOxygen'].forEach((t, i) => {
    const dataPoints: IDataPoint[] = [];

    docs.forEach((d) => {
      if (!d || !d.oxygenTherapyType || d.oxygenTherapyType !== t) return;
      if (!isPartialDate(d.date)) return;
      (d.treatmentResponses || [])
        .toSorted((s1, s2) => sortPartialDate(s1.date, s2.date))
        .forEach((tr, i) => {
          const endDate: PartialDate | undefined = d.treatmentResponses?.[i + 1]?.date || d.endDate || undefined;
          (tr.oxygenSaturation || tr.oxygenSaturation === 0) &&
            tr.date &&
            dataPoints.push({
              date: dateFromPartialUpdateTimeframe(tr.date),
              value: tr.oxygenSaturation,
              endDate: endDate ? dateFromPartialUpdateTimeframe(endDate) : undefined,
              id: 'oxygenSaturation',
              title: `${tr.oxygenSaturation} %`,
            });
        });
    });

    data.push({
      dataPoints: dataPoints,
      id: `oxygenSaturation${i}`,
      type: 'constantValueChart',
      legend: fm('graph.oxygenSaturation') + ` (${fm(`treatment.oxygenTherapy.opts.${t}`)})`,
    });
  });
  return data;
};

const sortTime = (time1?: Time, time2?: Time): number => {
  if (!time1 && !time2) return 0;
  if (!time1) return -1;
  if (!time2) return 1;
  if (time1[0] > time2[0]) return 1;
  if (time2[0] > time1[0]) return -1;
  if (time1[0] === time2[0] && time1[1] > time2[1]) return 1;
  if (time1[0] === time2[0] && time2[1] > time1[1]) return -1;
  return 0;
};

export const convertFlowRateToGraph = (
  hfncTherapyDocs: Array<IHFNCTherapy>,
  allDocs: Array<IControlProps>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): Array<IData> | undefined => {
  const hasRespiratoryFailure = patientHasRespiratoryFailureDiagnosis(getDiagnosisDocuments(allDocs));
  if (hfncTherapyDocs.length === 0 || !hasRespiratoryFailure) return undefined;
  const data: IData[] = [];
  const primaryDataPoints: IDataPoint[] = [];
  hfncTherapyDocs.forEach((d) => {
    if (!isPartialDate(d.date)) return;
    (d.settings || [])
      .toSorted((s1, s2) => sortPartialDate(s1.date, s2.date) || sortTime(s1.time, s2.time))
      .forEach((s, i) => {
        const endDate: PartialDate | undefined = d.settings?.[i + 1]?.date || d.endDate || undefined;
        const endTime: Time | undefined = d.settings?.[i + 1]?.time || [23, 59];
        exists(s.flowRate) &&
          s.date &&
          primaryDataPoints.push({
            date: dateFromPartialUpdateTimeframe(s.date, s.time),
            value: s.flowRate ?? 0,
            time: s.time,
            id: 'flowRate',
            endDate: endDate ? dateFromPartialUpdateTimeframe(endDate, endTime) : undefined,
            title: `${s.flowRate ?? '-'} l/min`,
            description: s.time ? formatTime(s.time) : undefined,
          });
      });
  });
  primaryDataPoints &&
    primaryDataPoints.length > 0 &&
    data.push({
      dataPoints: primaryDataPoints,
      id: 'flowRate',
      type: 'constantValueChart',
      legend: fm('graph.airFlowLegend'),
    });
  return data.length > 0 ? data : undefined;
};

export const convertSupplementalOxygenToGraph = (
  hfncTherapyDocs: Array<IHFNCTherapy>,
  allDocs: Array<IControlProps>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): Array<IData> | undefined => {
  const hasRespiratoryFailure = patientHasRespiratoryFailureDiagnosis(getDiagnosisDocuments(allDocs));
  if (hfncTherapyDocs.length === 0 || !hasRespiratoryFailure) return undefined;
  const data: IData[] = [];
  const primaryDataPoints: IDataPoint[] = [];
  hfncTherapyDocs.forEach((d) => {
    if (!isPartialDate(d.date)) return;
    (d.settings || [])
      .toSorted((s1, s2) => sortPartialDate(s1.date, s2.date))
      .forEach((s, i) => {
        const endDate: PartialDate | undefined = d.settings?.[i + 1]?.date || d.endDate || undefined;
        const endTime: Time | undefined = d.settings?.[i + 1]?.time || [23, 59];
        exists(s.supplementalOxygen) &&
          s.date &&
          primaryDataPoints.push({
            date: dateFromPartialUpdateTimeframe(s.date, s.time),
            value: s.supplementalOxygen ?? 0,
            time: s.time,
            id: 'supplementalOxygen',
            endDate: endDate ? dateFromPartialUpdateTimeframe(endDate, endTime) : undefined,
            title: `${s.supplementalOxygen ?? '-'} %`,
            description: s.time ? formatTime(s.time) : undefined,
          });
      });
  });
  primaryDataPoints &&
    primaryDataPoints.length > 0 &&
    data.push({
      dataPoints: primaryDataPoints,
      id: 'supplementalOxygen',
      type: 'constantValueChart',
      legend: fm('graph.oxygenPercentageLegend'),
    });
  return data.length > 0 ? data : undefined;
};

/**
 * Get a localizer for PAP therapy end reason.
 *
 * @param fm Intl localizer
 * @returns PAP therapy end reason localizer
 */
const getPapTherapyEndReasonLocalizer =
  (fm: (localizationKey: string) => string): FDataFieldLocalizer<IPAPTherapy> =>
  (papTherapy) => {
    // join the localized end reasons separated with a comma
    if (papTherapy.endReason) {
      return papTherapy.endReason.map((reason) => fm(`treatment.opts.papTherapyEndReason.${reason}`)).join(', ');
    }

    // fallback if there is no end reason
    else return '-';
  };

export const convertCentralAiToGraph = (
  papTherapyDocs: Array<IPAPTherapy>,
  resMedTreatmentResponses: Array<IReadonlyDocument>,
  allDocs: Array<IControlProps>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): Array<IData> | undefined => {
  if (resMedTreatmentResponses.length === 0) return undefined;
  const data: IData[] = [];
  const dataPoints: IDataPoint[] = [];
  const papDataPoints: IDataPoint[] = [];
  resMedTreatmentResponses.forEach((d) => {
    if (!isPartialDate(d?.date) || !isNumber(d?.centralAi)) return;
    dataPoints.push({
      date: dateFromPartialUpdateTimeframe(d.date),
      value: d.centralAi,
      id: 'centralAi',
      title: `${d.centralAi} %`,
    });
  });
  papTherapyDocs.forEach((ptd) => {
    (ptd.treatmentResponses || []).forEach((tr) => {
      patientHasSleepApneaDiagnosis(getDiagnosisDocuments(allDocs)) &&
        (tr.aiCentral || tr.aiCentral === 0) &&
        tr.date &&
        tr.startDateOfPeriodUnderReview &&
        papDataPoints.push({
          date: dateFromPartialUpdateTimeframe(tr.date),
          value: tr.aiCentral,
          id: 'residueCai',
          endDate: dateFromPartialUpdateTimeframe(tr.startDateOfPeriodUnderReview),
          title: `${tr.aiCentral}`,
        });
    });
  });

  dataPoints &&
    dataPoints.length > 0 &&
    data.push({
      dataPoints: dataPoints,
      id: 'centralAi',
      type: 'lineGraph',
      legend: fm('graph.centralAi'),
      linegraphProps: {
        hidePoints: true,
      },
    });

  papDataPoints &&
    papDataPoints.length > 0 &&
    data.push({
      dataPoints: papDataPoints,
      id: 'residueCai',
      type: 'constantValueChart',
      legend: fm('graph.residueCai'),
    });
  return data.length > 0 ? data : undefined;
};

export const convertTidalVolume95PercentileToGraph = (
  resMedTreatmentResponses: Array<IReadonlyDocument>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): Array<IData> | undefined => {
  if (resMedTreatmentResponses.length === 0) return undefined;
  const data: IData[] = [];
  const dataPoints: IDataPoint[] = [];
  resMedTreatmentResponses.forEach((d) => {
    if (!isPartialDate(d?.date) || !isNumber(d?.tidalVolume95Percentile)) return;
    dataPoints.push({
      date: dateFromPartialUpdateTimeframe(d.date),
      value: d.tidalVolume95Percentile,
      id: 'tidalVolume95Percentile',
      title: `${d.tidalVolume95Percentile} ml`,
    });
  });

  dataPoints &&
    dataPoints.length > 0 &&
    data.push({
      dataPoints: dataPoints,
      id: 'tidalVolume95Percentile',
      type: 'lineGraph',
      legend: fm('treatment.papTherapy.tidalVolume95Percentile'),
      linegraphProps: {
        hidePoints: true,
      },
    });
  return data.length > 0 ? data : undefined;
};

export const convertRespiratoryRate95PercentileToGraph = (
  resMedTreatmentResponses: Array<IReadonlyDocument>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): Array<IData> | undefined => {
  if (resMedTreatmentResponses.length === 0) return undefined;
  const data: IData[] = [];
  const dataPoints: IDataPoint[] = [];
  resMedTreatmentResponses.forEach((d) => {
    if (!isPartialDate(d?.date) || !isNumber(d?.respiratoryRate95Percentile)) return;
    dataPoints.push({
      date: dateFromPartialUpdateTimeframe(d.date),
      value: d.respiratoryRate95Percentile,
      id: 'respiratoryRate95Percentile',
      title: `${d.respiratoryRate95Percentile} ml`,
    });
  });

  dataPoints &&
    dataPoints.length > 0 &&
    data.push({
      dataPoints: dataPoints,
      id: 'respiratoryRate95Percentile',
      type: 'lineGraph',
      legend: fm('treatment.papTherapy.respiratoryRate95Percentile'),
      linegraphProps: {
        hidePoints: true,
      },
    });
  return data.length > 0 ? data : undefined;
};

export const convertSpontTToGraph = (
  resMedTreatmentResponses: Array<IReadonlyDocument>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
): Array<IData> | undefined => {
  if (resMedTreatmentResponses.length === 0) return undefined;
  const data: IData[] = [];
  const dataPoints: IDataPoint[] = [];
  resMedTreatmentResponses.forEach((d) => {
    if (!isPartialDate(d?.date) || !isNumber(d?.spontT)) return;
    dataPoints.push({
      date: dateFromPartialUpdateTimeframe(d.date),
      value: d.spontT,
      id: 'spontT',
      title: `${d.spontT} %`,
    });
  });

  dataPoints &&
    dataPoints.length > 0 &&
    data.push({
      dataPoints: dataPoints,
      id: 'spontT',
      type: 'lineGraph',
      legend: 'Spont T',
      linegraphProps: {
        hidePoints: true,
      },
    });
  return data.length > 0 ? data : undefined;
};

export const convertSpontCToGraph = (
  resMedTreatmentResponses: Array<IReadonlyDocument>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
): Array<IData> | undefined => {
  if (resMedTreatmentResponses.length === 0) return undefined;
  const data: IData[] = [];
  const dataPoints: IDataPoint[] = [];
  resMedTreatmentResponses.forEach((d) => {
    if (!isPartialDate(d?.date) || !isNumber(d?.spontC)) return;
    dataPoints.push({
      date: dateFromPartialUpdateTimeframe(d.date),
      value: d.spontC,
      id: 'spontC',
      title: `${d.spontC} %`,
    });
  });

  dataPoints &&
    dataPoints.length > 0 &&
    data.push({
      dataPoints: dataPoints,
      id: 'spontC',
      type: 'lineGraph',
      legend: 'Spont C',
      linegraphProps: {
        hidePoints: true,
      },
    });
  return data.length > 0 ? data : undefined;
};

export const convertTargetVentilationToGraph = (
  resMedTreatmentResponses: Array<IReadonlyDocument>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): Array<IData> | undefined => {
  if (resMedTreatmentResponses.length === 0) return undefined;
  const data: IData[] = [];
  const dataPoints: IDataPoint[] = [];
  resMedTreatmentResponses.forEach((d) => {
    if (!isPartialDate(d?.date) || !isNumber(d?.targetVentilation)) return;
    dataPoints.push({
      date: dateFromPartialUpdateTimeframe(d.date),
      value: d.targetVentilation,
      id: 'targetVentilation',
      title: `${d.targetVentilation} l/min`,
    });
  });

  dataPoints &&
    dataPoints.length > 0 &&
    data.push({
      dataPoints: dataPoints,
      id: 'targetVentilation',
      type: 'lineGraph',
      legend: fm('treatment.papTherapy.targetVentilation'),
      linegraphProps: {
        hidePoints: true,
      },
    });
  return data.length > 0 ? data : undefined;
};
